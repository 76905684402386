import request from '@/utils/request'

// 查询用户之前保存的编辑信息，若表中没有此用户的id记录，创建一条空的此用户记录
export function findPrecautions() {
  return request({
    url: `/tenant/precaution`,
    method: 'get'
  })
}

// 更新编辑框信息
export function updatePrecaution(data) {
  return request({
    url: `/tenant/precaution`,
    method: 'put',
    data
  })
}
